import { Controller } from "@hotwired/stimulus"
import { createFocusTrap } from "focus-trap"

export default class extends Controller {
  static targets = ["modalDialog", "modalWrapper"]
  static values = {
    delay: Number,
    onLoad: Boolean,
    clickOutsideDeactivates: { type: Boolean, default: true },
  }

  connect() {
    if (this.onLoadValue) {
      setTimeout(() => {
        this.show()
      }, this.delayValue * 1000)
    }
  }

  disconnect() {
    if (this.focusTrap) {
      this.focusTrap.deactivate()
    }
  }

  show() {
    this.modalWrapperTarget.classList.remove("hidden")
    document.body.classList.add("overflow-hidden")

    this.focusTrap = createFocusTrap(this.modalDialogTarget, {
      clickOutsideDeactivates: this.clickOutsideDeactivatesValue,
      escapeDeactivates: true,
      onDeactivate: () => this.hide(),
    })

    this.focusTrap.activate()
  }

  hide() {
    this.modalWrapperTarget.classList.add("hidden")
    document.body.classList.remove("overflow-hidden")
    if (this.focusTrap) {
      this.focusTrap.deactivate()
    }
  }
}
